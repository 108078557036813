<template>
  <div v-if="formAction" style="padding: 20px">
    <div v-loading="isLoading" v-if="formAction === 'select'">
      <div class="titles">{{ __("Name") }}</div>
      <div class="details">
        {{ contentFormInfo.api_key_name }}
      </div>
      <div class="titles">{{ __("API Key") }}</div>
      <div class="details">
        <div class="api-key-item">
          <span class="api-key">{{ contentFormInfo.api_key }}</span>
          <span><copier :value="contentFormInfo.api_key"></copier></span>
        </div>
      </div>
      <div class="titles">{{ __("Account") }}</div>
      <div class="details">
        {{ contentFormInfo.ac_name }}
      </div>
      <div class="titles">{{ __("Status") }}</div>
      <div class="details">
        {{ keyStatusDetail }}
      </div>

      <el-button @click="handleEdit" class="editBtn">{{
        __("Edit")
      }}</el-button>

      <el-button
        @click="handleDeleteNoUndo(contentFormInfo)"
        class="deleteBtn"
        :disabled="!canWrite()"
      >
        {{ __("Delete") }}
      </el-button>
    </div>
    <el-dialog
      :visible.sync="openModal"
      v-if="openModal"
      v-loading="isSubmitting || isLoading"
      :show-close="false"
      top="6vh"
      destroy-on-close
      fullscreen
    >
      <div class="account-form-dialog">
        <el-row type="flex">
          <el-col :span="12" :offset="6">
            <page-header :title="__('API Key')" :contentId="id" />
          </el-col>
        </el-row>
        <el-scrollbar :native="false">
          <el-form
            ref="contentForm"
            :rules="rules"
            :model="contentForm"
            label-position="top"
            size="large"
            hide-required-asterisk
          >
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Name')" prop="api_key_name">
                  <el-input v-model="contentForm.api_key_name"></el-input>
                </el-form-item>
              </el-col>
            </el-row>

            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item label="temp" prop="api_key">
                  <span slot="label" style="margin-right: 10px;">{{
                    __("API Key")
                  }}</span>

                  <template v-if="formAction === 'add'" slot="label">
                    <el-popover
                      placement="top-start"
                      width="400"
                      trigger="hover"
                      :content="__('Randomly generated API Key')"
                    >
                      <i slot="reference" class="el-icon-info icon"></i>
                    </el-popover>
                  </template>

                  <el-input
                    v-model="contentForm.api_key"
                    :disabled="true"
                  ></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('Is Active')" prop="is_enabled">
                  <el-checkbox
                    v-model="contentForm.is_enabled"
                    :checked="contentForm.is_enabled === 1"
                    >{{ __("Active") }}</el-checkbox
                  >
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item :label="__('User')" prop="user_id">
                  <span>
                    {{ apiKeyUserEmail }}
                  </span>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row type="flex" style="max-height: 32px; margin-bottom: 10px">
              <el-col style="width: 90px" :offset="6">
                <el-form-item :label="__('Account')"> </el-form-item>
              </el-col>
              <el-col :span="2" v-if="isSpScopeUser()">
                <el-checkbox
                  id="all-account-checkbox"
                  v-model="allAccountsChecked"
                >
                  {{ __("All Accounts") }}
                </el-checkbox>
              </el-col>
            </el-row>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item
                  prop="ac_id"
                  v-if="isSpScopeUser() && !allAccountsChecked"
                >
                  <el-select
                    id="select-account"
                    v-model="contentForm.ac_id"
                    :placeholder="__('Select Account')"
                    default-first-option
                    style="width: 100%"
                    filterable
                  >
                    <el-option
                      v-for="account in accounts"
                      :key="account.ac_id"
                      :label="account.ac_name"
                      :value="account.ac_id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
                <div class="ac-all-text" v-else>
                  <span>
                    {{
                      this.id === -1
                        ? selectedAccountName
                        : this.getDynamicAcName()
                    }}
                  </span>
                </div>
              </el-col>
            </el-row>
            <div v-if="this.show_permissions">
              <el-row type="flex" style="max-height: 32px;">
                <el-col style="width: 120px" :offset="6">
                  <el-form-item :label="__('Permissions')"> </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="
                      __('Reset to permissions attached to selected role')
                    "
                    placement="left-end"
                  >
                    <el-button
                      size="mini"
                      round
                      @click="resetToRolePermission"
                      >{{ __("Reset To default") }}</el-button
                    >
                  </el-tooltip>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    :content="resetPermissionContent"
                    placement="right-end"
                  >
                    <el-button
                      v-show="formAction === 'edit'"
                      type="primary"
                      size="mini"
                      round
                      @click="resetToOldPermissions"
                      >{{ __("Reset to Old Permission") }}
                    </el-button>
                  </el-tooltip>
                </el-col>
              </el-row>
              <el-row type="flex" v-if="allAccountsChecked">
                <el-col :span="12" :offset="6">
                  <div class="info" style="margin-bottom:5px">
                    <div class="warning-exclamation" />
                    <div style="margin:5px; display: flex">
                      <div>
                        <!-- eslint-disable-next-line -->
                        {{ __("Build level permissions are by default assigned for sp admin role.") }}
                      </div>
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex">
                <el-col :span="12" :offset="6">
                  <el-form-item prop="user_permissions">
                    <el-cascader-panel
                      ref="permCascader"
                      style="width: 100%"
                      v-model="contentForm.user_permissions"
                      :key="cascaderKey"
                      :options="permissions"
                      :props="perm_props"
                      clearable
                      filterable
                      v-loading="isFetchingPermissions"
                      @change="permissionChange"
                    ></el-cascader-panel>
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
            <div v-else style="margin-top: 0px;min-height: 50px">
              <el-row type="flex">
                <el-col :span="12" :offset="6">
                  <el-button
                    :disabled="!permissions.length"
                    type="text"
                    @click="showPermissions"
                    v-loading="isFetchingPermissions"
                    >{{ __("Edit Permissions") }}</el-button
                  >
                </el-col>
              </el-row>
            </div>
            <el-row type="flex">
              <el-col :span="12" :offset="6">
                <el-form-item>
                  <save-button
                    :disabled="!canWrite()"
                    type="primary"
                    @click="submitForm"
                    class="submitBtn"
                    :primaryKey="id"
                  />
                  <el-button class="cancelBtn" @click="closeModal"
                    >{{ __("Cancel") }}
                  </el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </el-scrollbar>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader";
import { mapState, mapActions, mapGetters } from "vuex";
import BaseContentInfoPanel from "@/views/build/content/mixins/BaseContentInfoPanel";
import { EventBus } from "@/EventBus";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import Copier from "@/components/Copier";
import _ from "lodash";
import { makeRandomId } from "@/utils/mocker";
import { validateApiKey, validateApiKeyNameForUser } from "@/api/apikeys";
import SaveButton from "@/components/SaveButton";

export default {
  mixins: [BaseContent, BaseContentInfoPanel],
  components: {
    PageHeader,
    Copier,
    SaveButton
  },

  data() {
    const checkSelectedAccount = (rule, value, callback) => {
      if (
        this.isSpScopeUser() &&
        !this.allAccountsChecked &&
        this.contentForm.ac_id === ""
      ) {
        callback(__("Account is required"));
      } else {
        callback();
      }
    };

    const validateApiKeyName = async (rule, value, cb) => {
      if (!value) {
        cb(__("API key name is required"));
      } else {
        let { data } = await validateApiKeyNameForUser(value, this.userScope);
        if (data.exists) {
          if (value !== this.contentFormInfo.api_key_name) {
            cb(__("cannot reuse api key name"));
          }
        } else {
          cb();
        }
      }
    };

    return {
      rules: {
        api_key: [
          {
            required: true,
            trigger: "blur",
            message: __("API Key is required")
          }
        ],
        api_key_name: [
          { required: true, trigger: "blur", validator: validateApiKeyName }
        ],
        ac_id: [
          { required: true, trigger: "change", validator: checkSelectedAccount }
        ]
      },
      accountFormKey: 0,
      scope: "",
      allAccountsChecked: false,
      isSubmitting: false,
      perm_props: { multiple: true },
      show_permissions: false
    };
  },
  computed: {
    ...mapState("apikeys", {
      apikeys: state => state.api_keys,
      isLoading: state => state.isLoading
    }),
    ...mapState("users", {
      current_user_data: state => state.current_user_data,
      loadingUsers: state => state.isLoading
    }),
    ...mapState("permissions", {
      permissions: "permissions",
      isFetchingPermissions: "isLoading"
    }),
    ...mapState("accounts", {
      accounts: state => state.accounts
    }),
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId,
      selectedAccountId: state => state.selectedAccountId,
      userScope: state => state.userType
    }),
    ...mapGetters("app", {
      selectedAccountName: "selectedAccountName",
      userRoleHasSystemRoleOrScope: "userRoleHasSystemRoleOrScope"
    }),
    cascaderKey() {
      return this.contentFormInfo.id > 0
        ? this.contentFormInfo.id
        : Math.random();
    },

    /**
     * Computed property for the API key status details
     */
    keyStatusDetail: function() {
      return this.contentFormInfo.is_enabled === 1
        ? __("Active")
        : __("Inactive");
    },

    /**
     * Display the email linked to the current API key
     */
    apiKeyUserEmail: function() {
      return this.$auth.user()
        ? this.$auth.user().email
        : __("No user detected");
    },

    /**
     * Text for the reset permission content
     * @returns {String} Translated message for the reset permission content
     */
    resetPermissionContent() {
      // eslint-disable-next-line
      return __("Reset user permissions to the state they were in when you initiated edit mode");
    }
  },
  methods: {
    ...mapActions("apikeys", {
      createUserApiKey: "createUserApiKey",
      updateUserApiKey: "updateUserApiKey",
      deleteContentMethod: "deleteUserApiKey",
      undoDeleteContent: "undoDeleteUserApiKey"
    }),
    ...mapActions("users", {
      listAccountsForUser: "listAccountsForUser"
    }),
    ...mapActions("permissions", {
      getUserPermissions: "getUserPermissions",
      setEmptyPermission: "setEmptyPermission"
    }),
    permissionChange(value) {
      let checkedNodes = this.$refs.permCascader.getCheckedNodes(true);
      let mandatory_permission = this.mandatory_permission(checkedNodes, value);
      if (
        mandatory_permission !== undefined &&
        mandatory_permission.length > 0
      ) {
        let clone_permission = _.clone(this.contentForm.user_permissions);
        clone_permission.push(mandatory_permission[0]);
        this.contentForm.user_permissions = clone_permission;
      }
    },
    isSpScopeUser() {
      return this.userScope === "sp" && !this.userRoleHasSystemRoleOrScope;
    },
    getDynamicAcName() {
      if (this.allAccountsChecked) {
        return __("All Accounts");
      } else {
        return this.contentFormInfo.ac_name;
      }
    },
    showPermissions() {
      this.show_permissions = true;
    },
    async buildUserPermissionTree(userId) {
      let options = {
        scope: this.scope,
        user_id: userId
      };

      if (this.isSpScopeUser() && !this.allAccountsChecked) {
        options = { ...options, overlap_from: "ac" };
      }

      await this.getUserPermissions(options);
    },
    setPermissions(userId) {
      let { account_mappings } = _.pick(
        this.current_user_data,
        "account_mappings"
      );
      this.scope = account_mappings[0].scope;
      this.buildUserPermissionTree(userId);
    },
    prepare_permission: checkedNodes => {
      return _.filter(
        _.map(checkedNodes, function(node) {
          let pathLabel = node.pathLabels.join(".");
          let searchString = pathLabel.toString();
          return searchString.split(".");
        })
      );
    },
    resetToRolePermission() {
      let checkedNodes = this.$refs.permCascader.getFlattedNodes(true);
      this.contentForm.user_permissions = this.prepare_permission(checkedNodes);
    },
    resetToOldPermissions() {
      if (this.contentFormInfo.user_permissions.length) {
        this.contentForm.user_permissions = this.contentFormInfo.user_permissions;
      }
    },
    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          const process =
            this.id === -1 ? this.createUserApiKey : this.updateUserApiKey;
          if (this.allAccountsChecked) {
            delete this.contentForm.ac_id;
          }
          process(this.contentForm)
            .then(data => {
              this.contentFormKey = this.contentFormKey + !this.id ? 0 : 1;
              // hack to refresh the user form to persist recent modifications
              this.isSubmitting = false;

              this.id === -1
                ? this.$message({
                    type: "success",
                    message: __("API Key added successfully")
                  })
                : this.$message({
                    type: "success",
                    message: __("API Key updated successfully")
                  });
              EventBus.$emit("list-changed", data.data);
              this.closeModal();
            })
            .catch(err => {
              console.log(err);
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.message
              });
            });
        }
      });
    },

    closeModal() {
      this.show_permissions = false;
      this.userExists = false;
      this.allAccountsChecked = false;
      this.setEmptyPermission();
      this.handleCancel();
    }
  },
  watch: {
    formAction: {
      immediate: true,
      deep: true,
      handler: async function(val) {
        if (val === "edit") {
          // for now setting the value to what backend has for api-key but can be dynamically changed
          // based on new updates to user's parent permissions
          this.setEmptyPermission();
          let user_id = this.contentFormInfo.user_id;
          if (this.contentFormInfo.ac_id === 0) {
            this.allAccountsChecked = true;
            this.$set(this.contentForm, "ac_id", "");
          }
          this.setPermissions(user_id);
        }
        if (val === "add") {
          let apiKey = makeRandomId(48);
          let res = await validateApiKey(apiKey, this.userScope);

          while (_.get(res, "data.exists", 1)) {
            apiKey = makeRandomId(48);
            res = await validateApiKey(apiKey, this.userScope);
          }

          this.$set(this.contentForm, "api_key", apiKey);
          this.$set(this.contentForm, "user_id", this.$auth.user().user_id);
          if (this.userScope === "ac") {
            this.$set(this.contentForm, "ac_id", this.selectedAccountId);
          }
          this.setPermissions(this.contentForm.user_id);
          // if (
          //   this.selectedServiceProviderId === "all" &&
          //   this.selectedAccountId === "all" &&
          //   this.userRole === "system"
          // ) {
          //   this.contentForm.account_mappings = [];
          //   await this.setScope("system");
          // }
        }
      }
    },
    allAccountsChecked: {
      immediate: true,
      handler: async function() {
        await this.buildUserPermissionTree(this.contentForm.user_id);
      }
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/tags.scss";
@import "~@/styles/node_common.scss";

::v-deep .el-select {
  .el-input.is-focus .el-input__inner {
    border-color: $content-theme-color;
  }

  .el-input__inner:hover {
    border-color: $content-theme-color;
  }

  .el-input__inner:focus {
    border-color: $content-theme-color;
  }
}
.api-key-item {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .api-key {
    word-break: break-word;
    padding-right: 5px;
  }
}
.info {
  color: #696969;
  display: flex;
  align-items: center;
  line-height: 1.5;
  margin-top: 5px;
  font-size: 0.8125rem;
  letter-spacing: 0.005rem;
}
.ac-all-text {
  margin-top: 5px;
  margin-bottom: 30px;
}
</style>
